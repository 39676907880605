import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Xtratuf</h2>
          <p>Over 50 years ago, a boot arrived in Alaska, as rugged and real as the people who live and work there. Like Alaska's people, those boots did their job no matter what the conditions.</p>
          <p>XTRATUF is built for the worst, so you perform your best. Keeps you upright, safe and moving forward in places where you feel most alive.</p>
          <p>Alaska Proven. Built For All.</p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-body/xtratuf/xtratuf-crew-right.jpg"
            className={brandBodyStyles.halfImage}
            alt="Xtratuf Atlantic Lady Crew"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
      <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
            <StaticImage
                src="../../assets/images/index/brand-body/xtratuf/xtratuf-square-01.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 10px 10px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/xtratuf/xtratuf-square-02.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 20px 10px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/xtratuf/xtratuf-square-03.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 10px 20px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/xtratuf/xtratuf-square-04.jpg"
                className={brandBodyStyles.halfImage}
                alt=""
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 20px 20px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
          </div>
          <div className={brandBodyStyles.highlights}>
            <h3>Highlight</h3>
            <h4>Legacy</h4>
            <p>Now an Alaskan icon, the Legacy Boot is 100% Waterproof, Flexible, and Durable—made to keep you dry, stable and always working comfortably. Featuring a non-marking, slip-resistant Chevron outsole, they're as reliable and tough as it gets.</p>
            <p>The Legacy Boot works, and in the eyes of Alaskans, that's a beautiful thing. Dubbed the "Alaskan Sneaker" it has been performance-tested and proven across the toughest stretches of water, from the Misty Fjords to Kodiak, from the Bering Sea to Harrison Bay.</p>
            <p>XTRATUF boots have become the badge of ultimate preparedness for the individuals of Alaska, the people who arrive as lone wolves and become part of a pack.</p>
          </div>
      </div>
      <div className={brandBodyStyles.strategy}>
        <h4>Xtratuf Strategy</h4>
        <p>Our strategy is the same as it has always been; to provide a reliable partner through thick and thin, keeping you safe, dry, and moving forward, one sure step at a time.</p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link to="/customer-resources/join-our-family-of-authorized-dealers/" title="Become a Dealer" className="CTAblack">
            Become a Dealer
          </Link>
          <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
            Customer Resources
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BrandBody
