import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/xtratufBrandHeader"
import BrandBody from "../../components/brand-bodies/xtratufBrandBody"

const XtratufPage = ({ pageContext, location }) => {
  const pageTitle = "Xtratuf"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      {/* description for search, hidden from page */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        Over 50 years ago, a boot arrived in Alaska, as rugged and real as the
        people who live and work there. Like Alaska's people, those boots did
        their job no matter what the conditions. XTRATUF is built for the worst,
        so you perform your best. Keeps you upright, safe and moving forward in
        places where you feel most alive. Alaska Proven. Built For All.
      </p>
    </>
  )
}

export default XtratufPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">XTRATUF</title>
    <meta
      name="description"
      content="Over 50 years ago, a boot arrived in Alaska, as rugged and real as the
      people who live and work there. Like Alaska's people, those boots did
      their job no matter what the conditions. XTRATUF is built for the worst,
      so you perform your best. Keeps you upright, safe and moving forward in
      places where you feel most alive. Alaska Proven. Built For All."
    />
  </>
)
