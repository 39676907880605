import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandHeaderStyles from "./BrandHeader.module.scss"

const BrandHeader = () => {
  return (
    <div className={brandHeaderStyles.wrapper}>
      <div
        className={brandHeaderStyles.heroImageWrapper}
        id="brand-header-hero"
      >
        <StaticImage
          src="../../assets/images/index/brand-headers/xtratuf/xtratuf-header-mobile.jpg"
          className={brandHeaderStyles.heroImageMB}
          alt=""
        />
        <StaticImage
          src="../../assets/images/index/brand-headers/xtratuf/xtratuf-header-tablet.jpg"
          className={brandHeaderStyles.heroImageTB}
          alt=""
        />
        <StaticImage
          src="../../assets/images/index/brand-headers/xtratuf/xtratuf-header-desktop.jpg"
          className={brandHeaderStyles.heroImageDT}
          alt=""
        />
      </div>
      <div className={`brand-container ${brandHeaderStyles.title}`}>
        {/* just a placeholder til dynamic breadcrumb component is built */}
        <h1>Xtratuf</h1>

        <p>Built AlaskaTUF to stand up to the rigors of commercial fishing.</p>

        <div className={brandHeaderStyles.buttons}>
          <a
            class="CTAblack"
            href="https://www.xtratuf.com/Home"
            title="Xtratuf"
          >
            Visit Website
          </a>
          {/* <a class="CTAalt" href="" title="">Watch Brand Video</a> */}
        </div>
      </div>
    </div>
  )
}

export default BrandHeader
